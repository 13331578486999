.tag {
  background-color: #f0e9e9;
  color: #666666;
  display: flex;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 2px;
}

.tag-name {
  color: #666666;
  font-size: 85%;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: hidden;
  padding: 3px 3px 3px 6px;
  white-space: pre-line;
}

.tag-delete {
  background-color: #f0e9e9;
  color: black;
  cursor: pointer;
  align-items: center;
  border: none;
  padding-left: 4px;
  padding-right: 4px;
}

.project-media-card .media-card {
  width: 185px;
  height: 150px;
  border: 1px solid rgb(214, 218, 226);
  margin-left: 20px;
  margin-top: 10px;
}

.project-media-card .media-info {
  width: 185px;
  height: 80px;
  background: rgb(249, 247, 247);
  border: 1px solid rgb(214, 218, 226);
  border-top: none;
  margin-left: 20px;
}

.project-booking .booking-text-color {
  color: #666666;
}

.project-booking .font-style {
  font-weight: bold;
  font-size: 12;
}

.attr-add-button {
  margin-top: 25px;
}

.no-label-attr-add-button {
  margin-top: 2px;
}

.settings .required:after {
  content: " *";
  color: red;
}

.project-bookings li:first-child {
  border-top: none;
}
