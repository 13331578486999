.reels .reels-tag {
  padding-left: 0;
}

.reels .reels-nav:hover {
  border-right: none;
}

.reels .reels-nav.active.active {
  border-right: none;
}

@media only screen and (max-width: 992px) {
  .reels .reels-tag {
    padding-left: 15px;
  }
}

.reels .reels-nav:hover {
  background-color: white;
}

.reels .reels-search-icon {
  position: relative;
  float: right;
  top: 25px;
  right: 25px;
}

.reels .reels-remove-icon {
  position: relative;
  float: right;
  top: 25px;
  right: 45px;
}