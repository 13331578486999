
.library-tab-content .table-scroll {
  max-width:768px;
  overflow:hidden;
}
.library-tab-content .table-wrap {
  width:100%;
  overflow:auto;
}
.library-tab-content .table-scroll table {
  width:100%;
  margin:auto;
  border-spacing:0;
}
.library-tab-content .table-scroll th, .table-scroll td {
  padding: 8px;
  width: 0;	
}

.library-tab-content .sort-up {
  float: right;
  position: relative;
  right: 5px;
  top: 5px;
}

.library-tab-content .sort-down {
  float: right;
  position: relative;
  left: 4px;
  top: 6px;
}

.library-tab-content .hide {
  visibility: hidden;
}

/*.library-tab-content .tab-content-table > tbody > tr > td,
.table > thead > tr > th {
  padding: 8px;
  width: 0;
}*/

.library-tab-content .edition {
  right: -4px;
}

.library-tab-content .edition-down {
  top: 6px;
  right: -13px;
}

.library-tab-content .visibility {
  visibility: visible;
}

@media (max-width: 768px) {
  .library-tab-content .visibility {
    visibility: hidden;
  }
}

@media (max-width: 460px) {
  .library-tab-content .sort-up {
    right: 0px;
    top: 0px;
  }
  .library-tab-content .sort-down {
    top: 2px;
    left: 6px;
  }
}
