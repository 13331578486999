.calender-view .react-calendar {
  width: 100%;
  border: 0;
  margin-top: 3rem;
}

.calender-view.calender-view .react-calendar {
  margin-top: 1rem;
}

.calender-view .show-calendar {
  width: 100%;
}

.calender-view
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.calender-view
  button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.calender-view button.react-calendar__navigation__label {
  color: var(--color-primary);
}

.calender-view .react-calendar__month-view__weekdays {
  background-color: #f3eeef;
  color: #666666;
  height: 35px;
}

.calender-view .react-calendar__navigation {
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 24px;
}

.calender-view .react-calendar__month-view {
  border-left: 1px solid #e3e3e3;
}

.calender-view .react-calendar__month-view__weekdays__weekday {
  border-right: 1px solid #e3e3e3;
}

.calender-view
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  border-right: 1px solid #e3e3dd;
  border-bottom: 1px solid #e3e3e3;
}

.calender-view
  button.react-calendar__tile.react-calendar__month-view__days__day {
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

.calender-view
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #6666;
}

.calender-view
  button.react-calendar__tile.react-calendar__month-view__days__day {
  color: #333333;
  overflow: inherit !important;
}

.calender-view .react-calendar__month-view__weekdays__weekday {
  padding: 0.8em;
}

.calender-view .react-calendar__tile {
  padding: 2.75em 0.5em;
}

.calender-view .tile-content {
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.calender-view .blue-background {
  background-color: var(--color-primary);
}

.calender-view .grey-background {
  background-color: #d3d3d3;
}

.calender-view .tile-content-link:hover {
  text-decoration: none;
}

.calender-view .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: white;
}

.calender-view .tile-content-text {
  color: white;
}

.custom-tooltip .tooltip-link {
  color: var(--color-primary-darker);
}

.calender-view .custom-tooltip {
  background: white;
  color: black;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  max-width: 190px;
  text-align: left;
  display: none;
  box-shadow: 1px 1px 5px #aaaaaa, -1px 0px 14px #aaaaaa;
  z-index: 1;
  overflow-y: auto;
  max-height: 325px;
}

.calender-view .tooltip-right {
  left: 18px;
}

.calender-view .tooltip-left {
  right: 36px;
}

.calender-view .badge:hover .custom-tooltip {
  display: block;
}

@media (max-width: 400px) {
  .calender-view .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
}
