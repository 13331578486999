.general-membership .build-report {
  width: 93%;
}

.general-membership .report-table {
  padding: 15px;
}

.general-membership .vertical-center {
  min-height: 50vh;
}

@media (max-width: 768px) {
  .general-membership .build-report {
    width: 91%;
  }
}
