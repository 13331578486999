.projectAttr .list-heading {
  font-size: 17px;
  margin-top: 7px;
  color: #333333;
}
.projectAttr ul {
  padding-left: 0px !important;
}

.projectAttr:hover .list-icons i,
.projectAttr:hover .list-icons .arc {
  cursor: pointer;
}

.projectAttr:hover .list-icons .arc {
  color: blue;
}

.project-attributes .slash {
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin-left: 3px;
  margin-right: 3px;
}

.projectAttr .list-group {
  margin-bottom: 0;
}

.projectAttr .react-datepicker__input-container input.form-control {
  width: 300px;
}

.project-attributes li:nth-child(4) .slash {
  display: none;
}

.project-attr-category .css-1oue0m2 {
  color: black;
  font-weight: bold;
}

.new-contest .contest-buttons {
  margin: auto;
}

.new-contest .react-datepicker__input-container {
  width: 100%;
}

.new-contest .custom-datepicker .calendar-icon {
  position: relative;
  bottom: 25px;
  right: -275px;
}

.project-contests-show .contest-name,
.project-contests-show .contest-detail,
.project-contests-votes .votes-detail {
  width: 100%;
}
