.project-item .project-name {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.project-item .project-creator {
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
}
