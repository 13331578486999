.tag-form-input .tag-form-input-loading {
  padding-right: 2.25rem;
}

.tag-form-input .tag-list {
  max-height: 12rem;
  overflow-y: auto;
  z-index: 2;
}

.tag-form-input .tag-list .tag-item .tag-text.tag-text-hover {
  background-color: #deebff;
}

.tag-form-input .loading-icons {
  font-size: 4px;
  bottom: 0.85rem;
  right: 0.5rem;
}

.tag-form-input .loading-icons .loading-icon {
  background-color: black;
  height: 1em;
  width: 1em;
  vertical-align: top;
  border-radius: 1em;
  animation: loading 1s ease-in-out infinite;
}

@keyframes loading {
  0%, 80%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

.tag-form-input .loading-icons .loading-icon:nth-child(1) {
  animation-delay: 0;
  margin-right: 1em;
}

.tag-form-input .loading-icons .loading-icon:nth-child(2) {
  animation-delay: 160ms;
  margin-right: 1em;
}

.tag-form-input .loading-icons .loading-icon:nth-child(3) {
  animation-delay: 320ms;
}
