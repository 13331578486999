.sidebar-main {
  position: fixed;
  top: 0px;
  z-index: 999;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  height: 100vh;
  width: 300px;

  min-height: 100vh;
  background: var(--color-primary);
  transition: all 0.4s ease-in;

  @media screen and (max-width: 500px) {
    min-height: 100%;
  }

  .navbar-logo {
    display: block;
    width: 100%;

    padding: 25px;

    color: white;
    text-align: center;
    span {
      display: inline-block;
      font-weight: bold;
      padding-left: 15px;
    }
    img {
      width: 25%;
      display: inline-block;
    }
  }
  .navbar-brand {
    color: white;
    width: 100%;
    padding: 9px 10px 9px 10px;
    border-left: 4px var(--color-primary) solid;
    &:hover {
      border-left: 4px var(--color-sidebar-accent) solid;
    }
  }
  .navbar-active {
    background: var(--color-primary-darker);
    border-left: 4px var(--color-sidebar-accent) solid !important;
  }
  .navbar-sub {
    background: var(--color-primary-darker);
    border-left: 4px rgba(0, 0, 0, 0.1) solid;
    padding-left: 30px;
  }
  .navbar-sub-active {
    color: var(--color-sidebar-accent);
  }
  .active-open + div {
    display: block;
  }
}

.container,
.content-container {
  transition: all 0.4s ease-in;
}

.top-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 10px;
  height: 55px;

  transition: all 0.4s ease-in;
}
