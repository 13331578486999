.super-admin-item {
  .super-admin-icon {
    transform: scale(0.8);
    &.super-admin-reset-icon {
      margin-left: -0.5rem !important;
    }
  }
  .super-admin-pen-icon {
    transform: scale(0.6);
  }
}