.form-control {
  border-radius: 0;
}

.inner-addon {
  position: relative;
}

.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

.left-addon .glyphicon {
  left: 0px;
}

.right-addon .glyphicon {
  right: 0px;
}

.left-addon input {
  padding-left: 30px;
}

.right-addon input {
  padding-right: 30px;
}

.color-red-red {
  color: red;
}

.file-input {
  height: 38px;
  width: 100%;
  border: 1px solid rgb(202, 202, 202);
  padding: 5px;
}
