.add-friend .tag {
  background-color: rgb(102, 102, 102) !important;
  color: white;
  display: inline-flex;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 2px;
}

.add-friend .tag-name {
  color: white;
  font-size: 85%;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: hidden;
  padding: 3px 3px 3px 6px;
}

.add-friend .tag-delete {
  background-color: rgb(102, 102, 102) !important;
  color: white;
  cursor: pointer;
  align-items: center;
  border: none;
  padding-left: 4px;
  padding-right: 4px;
}