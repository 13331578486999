.booking .radio-btn {
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #8c8c8c;
  border-radius: 50%;
  padding: 3px 0px 0px 3px;
  cursor: pointer;
}

.booking .selected {
  height: 13px;
  width: 12px;
  background-color: brown;
  border-radius: 50%;
}

.booking .search-icon {
  position: absolute;
  margin-left: 88%;
  color: #b3b3b3;
}

.booking .remove-icon {
  position: absolute;
  margin-left: 84%;
  color: #b3b3b3;
}

.booking .total-text {
  padding-top: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #8e2525;
}
