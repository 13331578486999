.booked-asset .react-datepicker__input-container {
  width: 100%;
}

.booked-asset .calendar-icon {
  position: relative;
  bottom: 25px;
  margin-left: 430px;
}

.booked-asset .custom-datepicker .form-control {
  height: 35px;
  width: 100%;
}