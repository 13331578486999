.dashboard {
  .centered {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
  }
  .card-dims {
    width: 100%;
    height: 495px;
  }
  .card-heading {
    font-size: 15px;
    font-weight: bold;
  }
  .list-group-item {
    padding: 5px;
    border: 1px solid #ececec;
    border-left: none;
    border-right: none;
    
    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .action-buttons button {
    width: 30px;
    height: 30px;
    padding: 1px;
    margin-right: 2px;
    margin-left: 2px;
  }
}
