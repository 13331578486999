.program-search .search {
  color: #b3b3b3;
  position: relative;
  float: right;
  top: 25px;
  right: 10px;
  cursor: pointer;
}

.program-search .remove {
  color: #b3b3b3;
  position: relative;
  float: right;
  top: 25px;
  right: 20px;
  cursor: pointer;
}
