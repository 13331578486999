.invoicing .mailing-label {
  width: 100%;
  padding: 20px 25px;
  color: #333333;
}

.invoicing .text-editor {
  width: 95%;
}

.invoicing .save-button {
  margin: auto;
}

.short-codes{
  font-size: 12px;
  color: #333;
  font-weight: 500;
  line-height: 15px;
}

div.switch-toggle{
  height: 100px;
  width: 200px;
}
