.organization-integration {
  width: 100%;
}

.organization-integration .border-link-hover:hover {
  border-color: #337ab7 !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 3px #3b87ca;
  text-decoration: none;
  transition: all 100ms ease-in;
}

.organization-integration .api-active-text-shadow {
  text-shadow: 0 0 5px #337ab7;
  text-shadow: 0 0 5px var(--color-primary);
}

.organization-integration .text-decoration-none:hover {
  text-decoration: none;
}

.organization-integration .logo-image {
  height: 50px;
  border-radius: 25px;
}

@media (max-width: 768px) {
  .organization-integration .logo-image {
    left: 0;
  }
}

#api-tooltip {
  min-width: 355px;
}
