.custom-react-switch-container {
  display: inline-block;
}

.custom-react-switch-container .custom-react-switch {
  box-shadow: 0 -2px 0 2px #ece7e7;
  --unchecked-toggle-text-color: #919797;
  --toggle-text-size: 13px;
}

.custom-react-switch-container .custom-react-switch .react-switch-bg {
  box-shadow: rgb(136, 135, 135) 0px 0px 3px inset;
}

.custom-react-switch-container .custom-react-switch .custom-toggle-text {
  font-size: var(--toggle-text-size);
  padding-top: 3px;
}

.custom-react-switch-container .custom-react-switch .text-toggle-unchecked {
  color: var(--unchecked-toggle-text-color);
  text-shadow: 0 0 1px #c0c0c0;
}

.custom-react-switch-container .custom-react-switch .text-toggle-checked {
  text-shadow: 0 0 1px #78b0c9;
}

.custom-react-switch-container .custom-react-switch .react-switch-handle {
  box-shadow: inset 0px 0px 0px 4px #fff, inset 0px 14px 10px -11px #ababab,
    0px 0px 2px 1px #7f7f7f;
}

.custom-react-switch-container
  .custom-react-switch
  .react-switch-bg
  > div:nth-of-type(2)
  > svg
  > path {
  fill: var(--unchecked-toggle-text-color);
}
