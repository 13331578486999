.organizations-list {
  .clear-text {
    float: right;
    position: relative;
    top: 35px;
    right: 25px;
    color: #b3b3b3;
  }
  .search-icon {
    bottom: 27px;
    right: 10px;
  }
  .remove-icon {
    bottom: 40px;
    right: 32px;
  }
  .action-column {
    width: 170px !important;
    min-width: 170px !important;
    max-width: 170px !important;
  }
}

.logoinput {
  padding-top: 3px !important;
  padding-left: 3px !important;
}

.reset-password-icon {
  right: -16px;
}