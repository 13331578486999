.activeStyle {
  cursor: pointer;
  padding: 10px 15px;
  color: rgb(87, 146, 242);
  font-size: 13px;
}

.inActiveStyle {
  cursor: pointer;
  padding: 10px 15px;
  color: #333333;
  font-size: 13px;
}

.tab-hr-line {
  border-bottom: 1px solid #eae6e6;
  color: black;
  margin-top: -11px;
}

.list-heading {
  color: black;
  font-weight: 500;
}

.card .font-16 {
  font-size: 15px;
}
@media only screen and (max-width: 380px) {
  .font-12 {
    font-size: 12px;
  }
}

.vertical-line {
  border-right: 1px solid #e3e3e3;
}

.li-image {
  height: 115px;
  width: 150%;
}

.custom-checkbox {
  background-color: white;
  cursor: pointer;
  width: 100%;
}
.custom-checkbox > .box {
  border: 1px solid #cccccc;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  float: left;
}

.custom-checkbox > .col-1 > .box {
  border: 1px solid #cccccc;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  float: left;
}

.custom-checkbox > p {
  width: 100%;
}

.media-image {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 992px) {
  .li-image {
    height: 80px;
  }
}
@media only screen and (max-width: 768px) {
  .li-image {
    height: 115px;
  }
  .li-link {
    display: flex;
    justify-content: center;
  }
}

.error-handler {
  background-color: lightgrey;
  width: 500px;
  margin: auto;
  margin-top: 7rem;
  border: 1px solid transparent;
  padding: 25px;
  background: white;
  border: 1px solid #ccc;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
  outline-offset: 0;
}

a:focus {
  outline: none;
  outline-offset: 0;
}

.webkit-fill-available {
  min-height: -webkit-fill-available;
}

.error-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.error-back-link {
  color: #2AA1DA;
  font-weight: 900;
}

.error-logo {
  margin-right: -5rem;
}

.not-found-text {
  max-width: 21rem;
}
