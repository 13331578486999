.custom-pagination .pagination {
  margin: 0px 10px 10px 0px !important;
  padding: 0px;
  /* border-top: 1px solid #c8c8c8; */
}

.custom-pagination .previous-button {
  display: none !important;
}

.custom-pagination .next-button {
  display: none !important;
}

.pagination > li > a,
.pagination > li > span {
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #000;
  background-color: #f7f7f7;
  border-color: #ddd;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  color: #000;
  background-color: #ebebeb;
  border-color: #ddd;
}

