.book-asset .react-calendar {
  width: 100% !important;
}

.book-asset .react-calendar__tile {
  height: 80px !important;
  margin: 1px;
  flex-basis: 14.05% !important;
  background: rgb(214, 226, 253);
}

.book-asset .react-calendar__tile--active:enabled:focus {
  background: #f0f0f0;
  color: black;
}

.book-asset .react-calendar__tile--active {
  background: #f0f0f0;
  color: black;
}

.book-asset .react-calendar__tile--now {
  background: #d3d3d3 !important;
}

.book-asset .react-calendar__month-view__weekdays {
  background: rgb(85, 85, 85);
  color: white;
}

.book-asset .currently-selected {
  background: rgb(248, 145, 251) !important;
}

.book-asset .previous-dates {
  background: #f0f0f0;
}

.book-asset .weekend {
  background: rgb(255, 255, 232);
}

.book-asset .booked-color {
  background-color: rgb(255, 232, 254);
}

.book-asset .booked-text {
  color: grey;
  font-size: 12px;
}

.book-asset .time-link {
  max-height: 40px;
  overflow-y: scroll;
}

.book-asset .form-control {
  width: 100% !important;
  background: white;
}

.book-asset .book-btn {
  background-color: #ffbb00;
  color: white;
  margin-left: 12px;
  width: 130px;
}

.book-asset .rdrCalendarWrapper {
  width: 100%;
}

.book-asset .rdrMonth {
  width: 100%;
}

.book-asset .vertical-line {
  border-left: 1px solid #e3e3e3;
}

.book-asset .calendar-navigation-label {
  font-weight: 800;
  font-size: 17px;
  font-family: sans-serif;
}

.book-asset .calendar-navigation-label {
  font-weight: 800;
  font-size: 17px;
  font-family: sans-serif;
}

.book-asset .calendar-navigation-border {
  border: 1px solid darkgrey;
  border-bottom: 0px;
}

@media only screen and (max-width: 992px) {
  .book-asset .react-calendar__tile {
    height: 30px !important;
    margin: 1px;
    padding: 13px 2px;
    flex-basis: 13.5% !important;
    background: rgb(214, 226, 253);
  }

  .book-asset .react-calendar__tile--active:enabled:focus {
    background: #f0f0f0;
    color: black;
  }

  .book-asset .react-calendar__tile--active {
    background: #f0f0f0;
    color: black;
  }

  .book-asset .react-calendar__month-view__weekdays {
    background: rgb(85, 85, 85);
    color: white;
  }

  .book-asset .previous-month {
    background: white;
  }

  .book-asset .weekend {
    background: rgb(255, 255, 232);
  }

  .book-asset .booked-color {
    background-color: rgb(255, 232, 254);
  }

  .book-asset .booked-text {
    color: grey;
    font-size: 12px;
  }

  .book-asset .time-link {
    max-height: 10px;
    overflow-y: scroll;
  }

  .book-asset .form-control {
    width: 70% !important;
    background: white;
  }

  .book-asset .book-btn {
    background-color: #ffbb00;
    color: white;
    margin-left: 12px;
    width: 100px;
  }

  .book-asset .rdrCalendarWrapper {
    width: 80%;
  }

  .book-asset .rdrMonth {
    width: 80%;
  }

  .book-asset .vertical-line {
    border-left: 1px solid #e3e3e3;
  }

  .book-asset react-calendar__month-view__weekdays__weekday {
    flex-basis: 14.2857% !important;
  }

  .book-asset {
    padding: 10px 170px 10px 170px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  li a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 320px) {
  .book-asset {
    padding: 10px 130px 10px 130px;
  }
}
