.donor-item .donor-thanked-group {
  width: 58%;
}

@media only screen and (max-width: 600px) {
  .donor-item .donor-thanked-group {
    width: 100%;
  }
}

.donor-item .donor-thumbnail {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
}
