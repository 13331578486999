.color-picker{
     position: relative;
}

.color-picker .color-sample {
     height: 32px;
     width: 32px;
     border: 1px solid black;
     border-radius: 5px;
     position: absolute;
     left: 4px;
     bottom: 2px;
     z-index: 2;
}

.color-picker .inbuilt-color-picker {
     position: absolute;
     z-index: 3;
     top: 67px
}