.search-browse .video {
  padding-right: 2.5rem;
}

.search-browse .dropdown-menu p {
  color: black;
  padding: 7px 15px;
  display: block;
  margin-bottom: 0;
}

.search-browse .search-browse-remove-icon {
  position: absolute;
  margin-top: 11px;
  right: 55px;
}

.search-browse .search-browse-search-icon {
  position: absolute;
  margin-top: 11px;
  right: 30px;
}