.flag-type .list-heading {
  font-size: 17px;
  margin-top: 7px;
  color: #333333;
}

.flagtypes .flagtype-visible {
  padding: 0 12px;
}

.flagtypes .new-flag {
  color: #4285f4;
  cursor: pointer;
  margin-top: 12px;
}
