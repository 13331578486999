.sidebar .heading {
  margin: 0;
  color: #f66c61;
  cursor: pointer;
}

.sidebar .asset-list {
  padding: 0 10px;
  list-style: none;
}

.sidebar .list-content {
  padding: 5px 0;
}

.sidebar .line {
  margin-top: 10px;
  margin-bottom: 5px;
}

.sidebar .registrant-name {
  margin-top: 5px;
  margin-bottom: 5px;
}

.sidebar .registrant-delete {
  position: relative;
  top: 10px;
  right: 15px;
}

.sidebar .file-attch-delete {
  color: #969696ba;
}

.file-uploader.custom-text-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.file-uploader .file-border-secondary {
  border-color: #e5e7ec;
}
