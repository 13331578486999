/* Container needed to position the overlay */
.search-field-container {
  position: relative;
  width: 100%;
}

/* Search field input padding for space for clear icon */
.search-field-container .search-field-input {
  margin-top: 29.5px;
  padding-right: 12%;
}

/* Search field clear icon overlay */
.search-field-container .search-field-clear-icon {
  position: absolute;
  top: 50%;
  right: 5%;
}
