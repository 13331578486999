.template-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100vw;
  height: 100vh;

  animation-name: fadeIn;
  animation-duration: 0.4s;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding-top: 50px;

  .template-container-bg {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
  }

  .template-modal {
    background: white;
    min-width: 90%;
    padding: 20px;
    border-radius: 7px;
    z-index: 1002;
    .modal-close-btn {
      right: -30px;
      top: -30px;
      padding: 5px 8px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.gjs-editor-cont {
  height: 75vh !important;
}
