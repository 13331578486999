.col-md-4 .media-input {
  margin-right: 13px;
}

.col-md-7 .description {
  margin-right: 13px;
}

section.col-md-8.dropzone {
  padding: 0 15px 15px;
}

h5.media-file-label {
  width: 100%;
  padding: 0 15px;
}

.row.mt-3.media-cards {
  margin: -7px;
}

img.dropbox-image {
  width: 40%;
  margin: 0 7.5rem;
}

.dropbox-button {
  border: 1px solid #e3e3e3;
}

.row.media.file-selected {
  margin: 0 15px;
}

input[type="text"] {
  width: 100%;
}

.dropbox-button {
  height: 100%;
}

.project-media .aws-section {
  padding: 1.6rem 0.8rem !important;
}

.project-media-modal .modal-content {
  max-height: 500px !important;
}

.project-media-modal .close {
  margin-right: 35px;
  font-size: xx-large;
  color: gray;
}

.project-media-modal .modal-body {
  overflow: auto !important;
}

.aws-modal .button-position {
  position: fixed;
  padding: 10px;
  background: #fff;
  z-index: 1;
  width: 97%;
}

@media (max-width: 1200px) {
  .dropbox-button {
    height: 100px;
  }
  img.dropbox-image {
    width: 50%;
    margin: 0 4.5rem;
  }
}

@media (max-width: 990px) {
  img.dropbox-image {
    width: 70%;
    margin: 0 2rem;
  }
}

@media (max-width: 767px) {
  img.dropbox-image {
    width: 20%;
    margin: 20px 12rem;
  }
  .media-selected-file {
    padding-left: 0;
  }
}
