.book-assets .radiotextsty {
  color: #969696;
  font-size: 15px;
}

.book-assets .customradio {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.book-assets .customradio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.book-assets .checkmark {
  position: absolute;
  top: 0;
  left: 10px;
  height: 22px;
  width: 22px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #bebebe;
}

/* On mouse-over, add a grey background color */
.book-assets .customradio:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.book-assets .customradio input:checked ~ .checkmark {
  background-color: white;
  border: 1px solid #bebebe;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.book-assets .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.book-assets .customradio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.book-assets .customradio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #6c0e11;
}

.book-assets .book-asset-list {
  list-style: none;
  padding: 0;
}

.book-assets hr {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -15px;
}

.book-assets .hide {
  visibility: hidden;
}

.book-assets .search-asset {
  position: relative;
  float: right;
  top: 25px;
  right: 15px;
}

.book-assets .remove {
  position: relative;
  float: right;
  top: 25px;
  right: 25px;
}
