.friends_account_tab .amount {
  color: black;
  font-size: 15px;
  margin-left: -18px;
}

.friends_account_tab .payment {
  padding: 10px 10px 10px 20px;
  border-top: 1px solid #d9d9d9;
}

.friends_account_tab .invoice-label {
  padding: 2px 7px 2px 7px;
  border-radius: 4px;
  height: 25px;
  background-color: #8fd337;
  color: white;
}
.friends_account_tab .invoice-label a {
  color: white;
}

.friends_account_tab .invoice-amount {
  color: black;
  font-size: 15px;
}

.friends_account_tab .credit-amount {
  color: black;
  font-size: 15px;
  margin-left: 58px;
}

.friends_account_tab .current-invoice {
  background-color: #ff8080;
}
.friends_account_tab .paid-invoice {
  background-color: #ffc107;
}
