.ReactTable .rt-thead .rt-tr {
  text-align: left
}
.ReactTable .rt-thead .rt-th:last-child{
  text-align: center
}
.constant .search-icon {
  position: relative;
  float: right; 
  right: 15px;
  top: 25px;
}

.constant .remove-icon {
  position: relative;
  float: right;
  right: 25px;
  top: 25px;
}