.custom-weekly-calendar{
  .custom-dayPicker-wrapper{
    z-index: 2;
    transform: translateX(-50%)
  }
  .custom-weekly-calendar-label{
    font-weight: 600;
    &:hover {
      background-color: #efefef;
      transition: background-color 200ms;
      cursor: pointer;
    }
  }
}