.custom-modal .modal-content {
  width: 100%;
  margin: auto;
}

.custom-modal .close {
  margin-right: 35px;
  font-size: xx-large;
  color: gray;
}
