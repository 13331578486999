.rental-requests {
  .action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      padding: 5px;
      width: 35px;
      height: 35px;
    }
  }
}
