.user-img {
  height: 115px;
}
.username {
  font-size: 20px;
}

@media only screen and (max-width: 992px) {
  .user-img {
    height: 80px;
  }
}
@media only screen and (max-width: 768px) {
  .user-img {
    height: 115px;
  }
  .username {
    text-align: center;
    font-size: 20px;
  }
}