.select-attr {
  z-index: 2;
}

.attr-list .list-group.attributes-list {
  width: 100%;
}

.attr-list .list-group-item.attribute-list:hover {
  cursor: pointer;
}

.attr-list .attr-edit {
  margin: 0 15px;
}

.short-attr .duration-picker {
  width: 30%;
  min-height: 37px;
  padding: 0 10px;
  border: 1px solid #cbcbcb;
}

.short-attr .remove-icon {
  position: relative;
  top: 40px;
  right: 17px;
  float: right;
}
