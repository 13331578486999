.volunteer {
  min-height: -webkit-fill-available;
}

.volunteer .volunteer-hour {
  background-color: #ffc107;
  width: 50%;
  padding: 5px;
}

.volunteer .approved {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: white;
  margin-right: 5px;
  & > p {
    margin: 6px 0px 0px 9px;
  }
}

.volunteer .deny {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: white;
  margin-left: 5px;
  & > p {
    margin: 6px 0px 0px 11px;
  }
}

.volunteer .react-datepicker__input-container {
  width: 100%;
}

.volunteer .calendar-icon {
  position: relative;
  margin-left: 210px;
  bottom: 25px;
}

.volunteer .icon-style {
  position: relative;
  top: 2px;
}

@media only screen and (max-width: 576px) {
  .margin_left_fix {
    margin-left: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .volunteer .react-datepicker__input-container {
    width: 100%;
  }

  .volunteer .calendar-icon {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .volunteer .react-datepicker__input-container {
    margin-left: 22px;
    width: 100%;
  }

  .volunteer .calendar-icon {
    margin-left: -12px;
  }
}

@media only screen and (max-width: 768px) {
  .volunteer .react-datepicker__input-container {
    width: 100%;
    margin-left: 0px;
  }

  .volunteer .calendar-icon {
    margin-left: 60px;
  }
}
