.calendar-modal .modal-content {
  width: 200%;
  margin-left: -50%;
}

.calendar-modal .close {
  margin-right: 35px;
  font-size: xx-large;
  color: gray;
}
