.new-program .update-button {
  background: var(--color-primary) !important;
  float: right;
}

.new-program .custom-datepicker .calendar-icon {
  position: relative;
  right: -468px;
}

.new-program .new-program-title {
  margin-top: 5px;
}

.new-program .react-datepicker__input-container {
  width: 100%;
}

.new-program .form-container {
  display: block;
  position: relative;
  padding-left: 23px;
  margin: 0 10px 12px 0;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.new-program .form-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.new-program .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15px;
  width: 15px;
  background-color: #dddddd;
  border: 0.5px solid #676767;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-program .form-container .checkmark:after {
  content: "";
  display: none;
}

.new-program .form-container input:checked ~ .checkmark:after {
  display: block;
}

.new-program .form-container .checkmark:after {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #676767;
}

.form-top-row-spacer {
  height: 49px;
}
