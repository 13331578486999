.contact-info select.dropdown {
  height: 32px;
  width: 150px;
  padding-left: 10;
}

.contact-info .react-datepicker-wrapper {
  width: 115%;
}

.contact-info .react-datepicker__input-container {
  width: 100%;
}

.contact-info .calendar-icon {
  position: relative;
  margin-left: 469px;
  bottom: 24px;
}

.contact-info .required:after {
  content: "*";
  color: red;
}

@media only screen and (max-width: 768px) {
  .contact-info .react-datepicker-wrapper {
    width: 115%;
    margin-left: -6%;
  }

  .contact-info .calendar-icon {
    margin-left: 6%;
  }
}
