.summary-program h3.workshop-name {
  color: #6c0e11;
  /* padding: 0 5px; */
}

.summary-program hr.horizontal-row {
  margin: 5px 0 20px;
}

.summary-program .col-md-3.workshop-button {
  margin-top: 25px;
}

.summary-program .row.heading {
  margin: 0;
}

.summary-program ul.options-list {
  padding: 0 11px;
}

.summary-program .options-list li {
  display: inline;
  border-right: 2px solid;
  padding: 0 9px;
}

.summary-program li.last-item {
  border-right: 0;
}

.summary-program .row.main_section,
.row.description {
  margin: 0;
}

.summary-program .row.description,
.row.schedules {
  padding: 0 20px;
}

.summary-program p.description {
  color: #333;
}

.summary-program .row.elements,
.row.program-expenses,
.row.schedules,
.row.schedule-title,
.row.schedule-list,
.row.schedule-button {
  width: 100%;
  margin: 0;
}

.summary-program .row.elements {
  padding: 0 5px;
}

.summary-program h5.element-heading {
  margin-bottom: 5px;
  color: #666666;
}

.summary-program p.element-detail {
  color: #333;
}

.summary-program .row.program-expenses {
  padding: 0px 20px;
  margin-top: 15px;
}

.summary-program th {
  background-color: #f3eeef;
}

.summary-program .amount {
  color: #666666;
  float: right;
}

.summary-program .row.schedule-button {
  margin-bottom: 20px;
}

.summary-program .col-md-4.sidebar {
  padding-right: 0;
}

.summary-program .react-calendar {
  width: 100%;
}

.summary-program .hide {
  visibility: hidden;
}
