.facility-detail-calendar .react-calendar {
  width: 100%;
  border: 0;
  margin-top: 3rem;
}

.facility-detail-calendar .react-calendar__tile--active {
  background: white !important;
}

.facility-detail-calendar .react-calendar__tile--active:enabled:hover {
  background: #e3e3e3 !important;
}
.facility-detail-calendar .react-calendar__tile--now {
  background: #D3D3D3!important;
}

.facility-detail-calendar.facility-detail-calendar .react-calendar {
  margin-top: 1rem;
}

.facility-detail-calendar .show-calendar {
  width: 100%;
}

.facility-detail-calendar
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.facility-detail-calendar
  button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.facility-detail-calendar button.react-calendar__navigation__label {
  color: var(--color-primary);
}

.facility-detail-calendar .react-calendar__month-view__weekdays {
  background-color: #f3eeef;
  color: #666666;
  height: 35px;
}

.facility-detail-calendar .react-calendar__navigation {
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 24px;
}

.facility-detail-calendar .react-calendar__month-view__weekdays__weekday {
  border-right: 1px solid #e3e3e3;
}

.facility-detail-calendar
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  border-right: 1px solid #e3e3dd;
  border-bottom: 1px solid #e3e3e3;
}

.facility-detail-calendar
  button.react-calendar__tile.react-calendar__month-view__days__day {
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

.facility-detail-calendar
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #6666;
}

.facility-detail-calendar
  button.react-calendar__tile.react-calendar__month-view__days__day {
  color: #333333;
  overflow: inherit !important;
}

.facility-detail-calendar .react-calendar__month-view__weekdays__weekday {
  padding: 1em;
}

.facility-detail-calendar .react-calendar__tile {
  padding: 2.75em 0.5em;
}

.facility-detail-calendar .tile-content {
  background-color: var(--color-primary);
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.facility-detail-calendar .tile-content-link:hover {
  text-decoration: none;
}

.facility-detail-calendar .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: white;
}
.facility-detail-calendar .badge {
  background-color: #5e2a2c;
}

.facility-detail-calendar .tile-content-text {
  color: white;
}

.custom-tooltip .tooltip-link {
  color: #5e2a2c;
}

.facility-detail-calendar .custom-tooltip {
  background: white;
  color: black;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  max-width: 190px;
  text-align: left;
  display: none;
  box-shadow: 1px 1px 5px #aaaaaa, -1px 0px 14px #aaaaaa;
  z-index: 1;
  overflow-y: scroll;
  max-height: 220px;
}
.facility-detail-calendar ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
.facility-detail-calendar ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, .5);
}

.facility-detail-calendar .tooltip-right {
  left: 18px;
}

.facility-detail-calendar .tooltip-left {
  right: 36px;
}

.facility-detail-calendar .badge:hover .custom-tooltip {
  display: block;
}
