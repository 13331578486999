.donor .calender-icon{
  position: relative;
  float: right;
  top: 38px;
  right: 10px;
  z-index: 1;
}
.donor{
  margin: 20px 30px 30px 25px;
}

.donor .input-style{
  z-index: 2;
}

.donor .react-datepicker-popper {
  z-index: 2;
}
