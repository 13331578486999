.new-library-type .contributer-name {
  margin-top: 29px;
}

.new-library-type .library-delete {
  position: relative;
  top: 37px;
  color: red;
}

.new-library-type .calendar-icon {
  margin-left: 165px;
  margin-top: 12px;
}

.new-library-type .required:after {
  content: "*";
  color: red;
}

.new-library-type .submit {
  background-color: var(--color-primary) !important;
}

.new-library-type .new-type-label {
  margin: 0 0 5px;
}

.new-library-type .form-control {
  min-height: 38px;
}

.new-library-type .input-fields {
  max-width: 100%;
}

.new-library-type .custom-datepicker .form-control {
  width: 245px;
}

.new-library-type .calendar-icon {
  margin-left: 220px;
}

@media only screen and (min-width: 768px) {
  .new-library-type .input-fields {
    max-width: 25%;
  }
}

@media only screen and (max-width: 768px) {
  .new-library-type .library-delete {
    padding-left: 15px;
    top: -5px;
  }

  .new-library-type .add-button {
    padding-left: 30px;
    position: relative;
    top: -35px;
  }

  .new-library-type .audio-video {
    top: -25px;
  }

  .new-library-type .custom-datepicker .form-control {
    width: 297px;
  }

  .new-library-type .calendar-icon {
    margin-left: 270px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .new-library-type .calendar-icon {
    margin-left: 120px;
  }

  .new-library-type .custom-datepicker .form-control {
    width: 147px;
  }

  .new-library-type .calendar-icon {
    margin-left: 130px;
  }
}
