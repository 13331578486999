.asset-items .serviced {
  background: #ffbb00;
  padding: 2px 5px 2px 5px;
  border-radius: 4px;
  color: white;
}

.asset-items .book-asset-button {
  height: 25px;
  padding: 0 10px;
  margin-left: 11px;
}

.asset-modal-container .asset-img {
  min-width: 128px;
  width: 50%;
}

.asset-modal-container .modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}