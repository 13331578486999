.friends .new-friend-btn {
  font-size: 14px;
  margin-bottom: 0 !important;
  background-color: var(--color-success);
  color: white;
}

.friends h3 {
  margin-top: 0 !important;
}
