.custom-campaign-form .custom-calendar {
  position: relative;
}

.custom-campaign-form .custom-calendar .custom-calendar-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  color: #bbbbbb;
  transform: translateY(-50%);
}

.custom-campaign-form .custom-radio {
  display: none;
}

.custom-campaign-form .custom-radio-button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #a1a1a2;
  transition: all 100ms ease;
}

.custom-campaign-form .custom-radio:checked ~ .custom-radio-button {
  background-color: #3478b6;
  box-shadow: 0 0 0 4px inset white;
}
