.edu-accr .section-header {
  margin: 10px 0px 5px 0px;
  border-bottom: 1px solid rgb(230, 230, 230);
  font-size: 16px;
  font-weight: bold;
  padding-left: 0px;
  text-transform: capitalize;
}

.edu-accr .checkbox {
  border: 1px solid #cccccc;
  width: 20px;
  height: 20px;
  background-color: white;
  cursor: pointer;
}

.edu-accr .accr-text {
  margin: 9px;
  font-size: 15px;
}
