.reports-account .build-report {
  margin-top: 25px;
}

.reports-account .button {
  margin-left: 15px;
}

@media (max-width: 786px) {
  .reports-account .button {
    margin-left: 0;
  }
  .reports-account .build-report {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}
