body {
  background-color: #d2d6df;
}

.vertical-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bg-light-blue {
  background: #f8f9fa;
}