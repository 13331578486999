.summary-flag .flag-title {
  background-color: #f9f7f7;
  padding: 5px 0;
}

.summary-flag .heading {
  color: #333333;
  margin: 8px 0 0;
  font-weight: bold;
}

.summary-flag .flag-table {
  margin: 0px;
  overflow-y: auto;
  width: 100%;
}

.summary-flag table.table {
  margin-bottom: 0;
}

.summary-flag .no-flag-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.summary-flag h4.no-flags-heading {
  font-weight: bold;
  font-size: 15px;
  margin-top: 12px;
}

.summary-flag .table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  line-height: 1.42857143;
  vertical-align: top;
  table-layout: fixed;
  border-bottom: 1px solid rgb(221, 221, 221);
  width: 2800px;
}

.summary-flag .flag-table th {
  padding-left: 22px;
}

.summary-flag .flag-table td {
  padding-left: 22px;
}

.summary-flag .table-description {
  table-layout: fixed;
  width: 1500px !important;
}

.summary-flag .table-delete-col {
  width: 200px !important;
}

.summary-flag .list-container {
  height: 205px !important;
  overflow-y: auto;
}

@media only screen and (max-width: 576px) {
  .table > thead > tr > th {
    font-size: 10px;
  }
}
