.add-to-schedule .react-calendar {
  width: 100% !important;
}

.add-to-schedule .react-calendar__tile {
  height: 80px !important;
  margin: 1px;
  flex-basis: 14.05% !important;
  background: rgb(214, 226, 253);
  overflow: auto !important;
}

.add-to-schedule .react-calendar__tile--active:enabled:focus {
  background: rgb(248, 145, 251) !important;
}

.add-to-schedule .react-calendar__tile--active {
  background: rgb(248, 145, 251) !important;
}

.add-to-schedule .react-calendar__month-view__weekdays {
  background: rgb(85, 85, 85);
  color: white;
}

.add-to-schedule .rdrCalendarWrapper {
  width: 100%;
}

.add-to-schedule .previous-dates {
  background: #f0f0f0;
}

.add-to-schedule .weekend {
  background: rgb(255, 255, 232);
}

.add-to-schedule .booked-color {
  background-color: rgb(255, 232, 254);
}

.add-to-schedule .rdrMonth {
  width: 100%;
}

.add-to-schedule .vertical-line {
  border-left: 1px solid #e3e3e3;
}

@media only screen and (max-width: 992px) {
  .add-to-schedule .react-calendar {
    width: 65% !important;
  }

  .add-to-schedule .react-calendar__tile {
    height: 30px !important;
    margin: 1px;
    flex-basis: 13.5% !important;
    background: rgb(214, 226, 253);
  }

  .add-to-schedule .react-calendar__tile--active:enabled:focus {
    background: rgb(248, 145, 251) !important;
  }

  .add-to-schedule .react-calendar__tile--active {
    background: rgb(248, 145, 251) !important;
  }

  .add-to-schedule .react-calendar__month-view__weekdays {
    background: rgb(85, 85, 85);
    color: white;
  }

  .add-to-schedule .rdrCalendarWrapper {
    width: 80%;
  }

  .add-to-schedule .rdrMonth {
    width: 80%;
  }

  .add-to-schedule .react-calendar__month-view__weekdays__weekday {
    flex-basis: 14.2857% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  li a {
    font-size: 13px;
  }
}
