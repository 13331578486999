.accr .section-header {
  margin: 10px 0px 5px 0px;
  border-bottom: 1px solid rgb(230, 230, 230);
  font-size: 16px;
  font-weight: bold;
  padding-left: 0px;
  text-transform: capitalize;
}

.accr .checkbox {
  border: 1px solid #cccccc;
  width: 20px;
  height: 20px;
  background-color: white;
  cursor: pointer;
}

.accr .accr-text {
  margin: 9px;
  font-size: 15px;
}

.accr-modal .image-file-upload {
  height: 35px;
  width: 100%;
  border: 1px solid #cccccc;
  padding: 0.20rem 0.45rem 0.20rem 0.45rem; 
}
