.members .search-icon {
  position: relative;
  float: right;
  top: 25px;
  padding-right: 10px;
  color: #b3b3b3;
}

.members .remove-icon {
  position: relative;
  float: right;
  top: 25px;
  padding-right: 10px;
  color: #b3b3b3;
}

@media only screen and (max-width: 768px) {
  .members .username {
    display: flex;
    justify-content: center;
  }
}
.members .search-icon {
  float: right;
  right: 5px;
  top: 25px;
}

.members .remove-icon {
  float: right;
  right: 8px;
  top: 25px;
}
.members .member-by-organization{
  overflow-y: scroll;
  max-height: 250px;
  height: 400px;
}

