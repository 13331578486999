.ams-super-admins .icon-scale-down,.pen-icon-scale-down {
  transform: scale(0.8);
  font-size: 0.875rem;
}

.ams-super-admins .pen-icon-scale-down {
  transform: scale(0.6);
}

.ams-super-admins .icon-scale-down .reset-password-sync-icon {
  left: 6px;
  bottom: 4px;
}

.ams-super-admins .super-admin-toggle-icon {
  margin: -0.5rem;
}

.ams-super-admins .search-filter-negative-margin {
  margin-right: -1.5rem;
}
