.summary hr {
  margin-top: 0;
  margin-bottom: 0;
}

.summary .list-container {
  height: 205px !important;
  overflow-y: auto;
}

.summary .button {
  width: 130px;
}

.margin_fix {
  padding: 0px;
}
.summary .label {
  color: white;
  padding: 5px;
}
.summary .other {
  background-color: #e7e7e7;
  color: black;
}

@media only screen and (max-width: 1000px) {
  .summary .button {
    width: 100px;
  }
}

@media only screen and (max-width: 576px) {
  .button {
    width: 110px;
    font-size: 12px;
    margin-right: -35px;
    margin-left: 20px;
  }

  .summary .buttonb {
    width: 115px;
    font-size: 12px;
  }

  table {
    font-size: 12px;
  }

  table,
  tr,
  td {
    padding: 3px !important;
  }

  .summary .table-delete-col {
    font-size: 10px;
  }
}

.user-programs .line {
  border-top: 1px solid rgb(204, 204, 204);
}

.user-programs .side-text-color {
  color: #007bff;
}

.user-program .registered-text {
  background-color: yellow;
  border: 1px solid grey;
  border-radius: 5%;
  padding: 5px;
}

.user-program .text-color {
  color: #da7171;
}
.button-margin {
  margin-left: 35px;
}

.user-programs .remove-icon {
  right: 0.5rem;
  top: 11px;
}

.user-programs .program-search-form {
  height: 38px;
}

.user-programs .pr-md-c-4 {
  padding-right: 0;
}

.user-programs .px-md-c-4 {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 768px) {
  .user-programs .pr-md-c-4 {
    padding-right: 1.5rem;
  }
  .user-programs .px-md-c-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
