.system-settings-accounting .active-style {
  border-top: 3px solid #ffbb00;
  border-radius: 0px;
  border-right: 1px solid #e3e3e3;
  cursor: pointer;
  padding: 10px 15px;
  color: #333333;
}

.system-settings-accounting .inactive-style {
  border-radius: 0px;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;
  padding: 10px 15px;
  color: #333333;
}

.active-tab {
  cursor: pointer;
  padding: 10px 5px;
  color: rgb(87, 146, 242);
  font-size: 13px;
}

.inactive-tab {
  cursor: pointer;
  padding: 10px 5px;
  color: #333333;
  font-size: 13px;
}

.quickbooks-customers th {
  font-weight: 200;
  font-size: 14px;
  border: 1px solid rgb(225, 225, 225);
  border-top: 0px;
}

.quickbooks th {
  border: 1px solid rgb(225, 225, 225);
  border-bottom: 0px;
}

.system-setting-accounting-codes .slash {
  color: black;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 600;
}

.system-setting-accounting-codes li:nth-child(2) .slash {
  display: none;
}

.system-setting-accounting-codes .innertab {
  border-top: none;
  border: none;
  font-size: 14px;
}

.quickbooks-invoices .pagination {
  margin: 10px 0 10px 0 !important;
}

.mt-n-50 {
  margin-top: -50px;
}
.mt-n-25 {
  margin-top: -25px;
  left: 80px;
}

.search-icon {
  position: relative;
  float: right;
  right: 5px;
  bottom: 41px;
}
.asset-name-font {
  font-size : 13px;
}
.search-icon-accounting {
  position: absolute;
  right: 25px;
  top: 10px;
}

.remove-icon {
  position: absolute;
  right: 40px;
  top: 10px;
}

.remove-icon-accounting {
  position: absolute;
  right: 50px;
  top: 10px;
}

.quickbook-settings .date-clear-icon {
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  z-index: 1;
}